@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --main-purple: #ae90fe;
    --bg-white: #ffffff;
    --bg-lilaclight: #f4f0ff;
    --bg-disabled: #e4e4e7;

    --text-white: #fff;
    --text-black: #000;
    --text-disabled: #e4e4e7;

    --stroke-default: #00000080;
    --stroke-hover: #000000;
    --stroke-white: #fff;
    --stroke-error: #ea0000;

    --button-primary-bg-default: #ae90fe;
    --button-primary-bg-hover: #9e7cfc;
    --button-primary-bg-pressed: #8f68fc;
    --button-primary-bg-disabled: #e4e4e7;
    --button-primary-text-default: #000;
    --button-primary-text-disabled: #a4a4a5;

    --button-secondary-bg-default: #ffffff;
    --button-secondary-text-default: #000;
    --button-secondary-bg-hover: #f7f7f7;
    --button-secondary-text-hover: #000;
    --button-secondary-bg-pressed: #ebebeb;
    --button-secondary-text-pressed: #000;
    --button-secondary-stroke-disabled: #e4e4e7;
    --button-secondary-text-disabled: #a4a4a5;

    --button-deletion-primary-bg-default: #ea0000;
    --button-deletion-primary-bg-hover: #d90000;
    --button-deletion-primary-bg-pressed: #c20000;
    --button-deletion-primary-bg-disabled: #e4e4e7;
    --button-deletion-primary-text-default: #fff;
    --button-deletion-primary-text-hover: #fff;
    --button-deletion-primary-text-pressed: #fff;
    --button-deletion-primary-text-disabled: #a4a4a5;

    --bg-backdrop-dark: rgba(61, 60, 88, 0.37);
  }
}
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  height: 100%;
  width: 100%;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiFormControl-root {
  height: 66px;
  padding: 20px !important;
}
.MuiInputBase-root {
  margin: 0 !important;
  height: 100%;
}
.MuiInputBase-root::after {
  content: none !important;
}
.MuiInputBase-root::before {
  content: none !important;
}
.MuiAutocomplete-popper {
  margin-top: 5px !important;
}
.MuiPaper-root {
  border-radius: 10px !important;
  margin-top: 20px;
}
.MuiFormLabel-root {
  color: #000 !important;
  margin-left: 20px;
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 7px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
